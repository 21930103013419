import React from "react"
import { formatPrice } from "../../../utils/format-price"

const Discounts = ({ discounts }) => {
  let discountType = discounts[0].value.percentage === undefined // if true fixed amount discount else percentege amount
  let isAutomaticDiscount = discounts[0].title !== undefined // false automatic discount is applied

  return (
    <div className="flex items-center justify-between">
      <p className="font-semibold">Koda za Popust</p>
      <p className="text-red-600 font-medium">
        <span>
          {isAutomaticDiscount ? discounts[0].title : discounts[0]?.code}
        </span>{" "}
        (
        {discountType ? (
          <span>
            -
            {formatPrice(
              discounts[0]?.value.currencyCode,
              discounts[0]?.value.amount
            )}
          </span>
        ) : (
          <span>-{discounts[0]?.value?.percentage}%</span>
        )}
        )
      </p>
    </div>
  )
}

export default Discounts
